<template>
  <div class="form-control">
    <vue-editor
      class="collect-editor"
      :class="{ 'collect-editor--invalid': validateError }"
      v-model="content"
      :editorToolbar="customToolbar"
      useCustomImageHandler
      @image-added="handleImageAdded"
      @image-removed="handleImageDelete"
    ></vue-editor>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
export default {
  props: { value: { type: String }, validateError: { type: String } },
  components: {
    VueEditor,
  },
  data() {
    return {
      content: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4] }],
        ["bold", "italic", "underline"],
        [{ align: "" }, { align: "center" }, { align: "justify" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote"],
        ["link", "image", "video"],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    };
  },
  created() {
    this.content = this.value;
  },
  watch: {
    content(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage", "apiDeleteUploadImage"]),
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let image = await this.apiPostUploadImage(file);
      Editor.insertEmbed(cursorLocation, "image", image);
      resetUploader();
    },

    async handleImageDelete(file) {
      await this.apiDeleteUploadImage(file);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  width: 100%;
  position: relative;
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
}
</style>