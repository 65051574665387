<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="div" class="art" id="scroll-block">
      <header class="art__header">
        <h3 class="art__header_title">{{ art.id ? "Edit" : "Add" }} Art</h3>
        <div class="art__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Item"
            @click="$emit('removeArt', art)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid || !art.images.length"
            @click="saveArt"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Required Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Title"
              :required="true"
              :validateError="errors[0]"
              v-model="art.title"
              :element="art.title"
              :toggle="true"
              @input="art.title = $event"
            >
              <template v-slot:icon>
                <SimpleToggle
                  color="blue"
                  v-model="art.is_show_title"
                  :selected="art.is_show_title"
                  @input="art.is_show_title = $event ? true : false"
                ></SimpleToggle
              ></template>
            </BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <AutocompleteInput
              :class="{ 'autocomplete-input--invalid': errors[0] }"
              placeholder="Creator"
              :required="true"
              v-model="artistTitle"
              :validateError="errors[0]"
              :loading="artistsLoading"
              :matches="artists"
              :value="art.artist.title"
              @searchMatches="apiGetSearchedArtists({ search: $event })"
              @selectMatch="
                art.artist = $event;
                artistTitle = $event.title;
              "
              @input="artistTitle = $event"
            ></AutocompleteInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="art.slug"
              :element="art.slug"
              @input="art.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example: {{ `${url}/categories/ceramics/${art.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Status"
              v-model="art.status"
              type="status"
              :selected="art.status"
              :items="[
                { key: '1', title: 'Active' },
                { key: '0', title: 'Inactive' },
              ]"
              @action="art.status = $event.key"
            ></BaseSelect>
          </div>
        </div>
        <div class="form__row">
          <SimpleToggle
            placeholder="Hide on Collection"
            color="blue"
            v-model="art.is_hide_on_collection"
            :selected="art.is_hide_on_collection"
            @input="art.is_hide_on_collection = $event ? true : false"
          ></SimpleToggle>
        </div>
        <ValidationProvider tag="div" class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-category"
            v-model="art.categories"
            :creating="false"
            :focusOpen="true"
            :loading="categoriesLoading"
            :items="art.categories"
            :matches="matchesCategories"
            placeholder="Categories"
            @selectItem="art.categories = $event"
            @searchMatches="apiGetMatchedCategories"
          ></MultipleAutocompleteInput>
        </ValidationProvider>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-tag"
            :loading="tagsLoading"
            :items="art.tags"
            :matches="matches"
            placeholder="Tags"
            @addItem="addTag"
            @selectItem="art.tags = $event"
            @searchMatches="apiGetMatchedTags"
          ></MultipleAutocompleteInput>
        </div>
        <div class="form__row form__row--label form__row--bordered form__row--justify-start">
          <span class="form__row_label">Image <span class="form__row_label_required">*</span></span>
          <UploadReorderImages
            :images="art.images"
            :loadingImage="loadingImage"
            @editImage="editImage"
            @removeImage="removeImage"
            @upload="upload"
            @reorder="art.images = $event"
          ></UploadReorderImages>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Details</h3>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="Description"
            v-model="art.description"
            :element="art.description"
            :validateError="errors[0]"
            @input="art.description = $event"
          ></BaseTextarea>
        </ValidationProvider>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Provenance"
              v-model="art.provenance"
              :element="art.provenance"
              :validateError="errors[0]"
              @input="art.provenance = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Date"
              :validateError="errors[0]"
              v-model="art.date"
              :element="art.date"
              @input="art.date = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Location"
              :validateError="errors[0]"
              v-model="art.location"
              :element="art.location"
              @input="art.location = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Attributes</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Height"
              v-model="art.height"
              :validateError="errors[0]"
              :element="art.height"
              @input="art.height = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Width"
              v-model="art.width"
              :validateError="errors[0]"
              :element="art.width"
              @input="art.width = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Depth"
              v-model="art.depth"
              :validateError="errors[0]"
              :element="art.depth"
              @input="art.depth = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Weight"
              v-model="art.weight"
              :validateError="errors[0]"
              :element="art.weight"
              @input="art.weight = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Condition"
              :validateError="errors[0]"
              v-model="art.condition"
              :element="art.condition"
              @input="art.condition = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Copyright"
              :validateError="errors[0]"
              v-model="art.copyright"
              :element="art.copyright"
              @input="art.copyright = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Connections</h3>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-editorials"
            v-model="art.editorials"
            :creating="false"
            :focusOpen="true"
            :loading="editorialsLoading"
            :items="art.editorials"
            :matches="editorials"
            placeholder="Publications"
            @selectItem="art.editorials = $event"
            @searchMatches="apiGetSearchedEditorials"
          ></MultipleAutocompleteInput>
        </div>

        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-books"
            v-model="art.books"
            :creating="false"
            :focusOpen="true"
            :loading="booksLoading"
            :items="art.books"
            :matches="books"
            placeholder="Book"
            @selectItem="art.books = $event"
            @searchMatches="apiGetBooks"
          ></MultipleAutocompleteInput>
        </div>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-blogs"
            v-model="art.blogs"
            :creating="false"
            :focusOpen="true"
            :loading="blogsLoading"
            :items="art.blogs"
            :matches="blogs"
            placeholder="Blog"
            @selectItem="art.blogs = $event"
            @searchMatches="apiGetSearchedBlogs"
          ></MultipleAutocompleteInput>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import UploadReorderImages from "@/components/UploadReorderImages.vue";
import Editor from "@/components/inputs/Editor.vue";
import AutocompleteInput from "@/components/inputs/AutocompleteInput.vue";
import SimpleToggle from "@/components/inputs/SimpleToggle.vue";

import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    UploadReorderImages,
    Editor,
    AutocompleteInput,
    MultipleAutocompleteInput,
    SimpleToggle,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      art: {
        title: "",
        artist: { id: null, title: "" },
        categories: [],
        slug: "",
        status: null,
        images: [],
        tags: [],
        description: "",
        provenance: "",
        date: "",
        location: "",
        height: "",
        width: "",
        depth: "",
        weight: "",
        condition: "",
        copyright: "",
        editorials: [],
        books: [],
        blogs: [],
        search_image: "",
        is_show_title: true,
        is_hide_on_collection: false,
      },
      artistTitle: "",
      removedImages: [],
      loadingImage: false,
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.art = JSON.parse(JSON.stringify(this.item));
      this.artistTitle = JSON.parse(JSON.stringify(this.item.artist.title));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("artworks", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("tags", {
      matches: "getMatchedTags",
      tagsLoading: "getChangesLoading",
    }),
    ...mapGetters("artists", {
      artists: "getSearchedArtists",
      artistsLoading: "getChangesLoading",
    }),
    ...mapGetters("categories", {
      matchesCategories: "getMatchedCategories",
      categoriesLoading: "getLoading",
    }),
    ...mapGetters("books", {
      books: "getBooks",
      booksLoading: "getLoading",
    }),
    ...mapGetters("blogs", {
      blogs: "getSearchedBlogs",
      blogsLoading: "getChangesLoading",
    }),
    ...mapGetters("editorials", {
      editorials: "getSearchedEditorials",
      editorialsLoading: "getChangesLoading",
    }),
  },
  watch: {
    art: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.status != null) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapActions("tags", ["apiPostTag", "apiGetMatchedTags"]),
    ...mapActions("artists", ["apiGetSearchedArtists"]),
    ...mapActions("categories", ["apiGetMatchedCategories"]),
    ...mapActions("books", ["apiGetBooks"]),
    ...mapActions("blogs", ["apiGetSearchedBlogs"]),
    ...mapActions("editorials", ["apiGetSearchedEditorials"]),
    ...mapMutations("artworks", ["setError"]),

    async upload(file) {
      this.loadingImage = true;
      let image = await this.apiPostUploadImage(file);
      if (image) {
        this.art.images.push(image);
      }
      this.loadingImage = false;
    },
    async editImage({ el, idx }) {
      this.loadingImage = true;
      let image = await this.apiPostUploadImage(el);
      let removedEl = this.art.images.splice(idx, 1, image);
      this.removedImages.push(removedEl);
      this.loadingImage = false;
    },
    async removeImage(idx) {
      this.loadingImage = true;
      let removedEl = this.art.images.splice(idx, 1);
      this.removedImages.push(removedEl);
      this.loadingImage = false;
    },
    async addTag(val) {
      let data = { title: val };
      let result = await this.apiPostTag(data);
      if (result.success) {
        this.art.tags.push(result.tag);
        this.$emit("updateTag");
      } else {
        this.$toasted.show(result.error, {
          duration: 2000,
          type: "error",
        });
      }
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid && art.images.length > 0
              ? this.saveArt()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      //this.$refs.observer.validate();
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    async saveArt() {
      this.art.search_image = this.art.images?.[0] ?? "";
      if (this.art.categories.length === 0) {
        await this.apiGetMatchedCategories({ search: "", excludeIdx: [] });
        const searchCategory = this.matchesCategories.find(category => {
          if (category.title === "uncategorized") return category;
        });
        this.art.categories.push({ ...searchCategory });
      }
      let data = {
        ...this.art,
        tags: this.art.tags.map(el => {
          return el.id.toString();
        }),
        categories: this.art.categories.map(el => {
          return el.id.toString();
        }),
        editorials: this.art.editorials.map(el => {
          return el.id.toString();
        }),
        books: this.art.books.map(el => {
          return el.id.toString();
        }),
        blogs: this.art.blogs.map(el => {
          return el.id.toString();
        }),
      };
      if (this.art.id) {
        this.$emit("updateArt", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createArt", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.art {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>

