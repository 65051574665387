<template>
  <div class="uploader-images">
    <draggable
      class="uploader-images__body"
      v-model="reorderImages"
      :group="id"
      @start="drag = true"
      @end="drag = false"
      @change="changeOrder"
    >
      <UploadImage
        class="uploader-images__uploader uploader--reorder"
        v-for="(i, idx) in reorderImages"
        :id="`image-${idx}`"
        :key="`image-${idx}`"
        :image="i"
        :loadingImage="loadingImage"
        reorder
        @removeImage="$emit('removeImage', $event)"
      />
    </draggable>
    <UploadImage
      class="uploader--reorder uploader--empty"
      :loadingImage="loadingImage"
      @upload="$emit('upload', $event)"
    />
  </div>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";
import draggable from "vuedraggable";

export default {
  components: { UploadImage, draggable },
  props: {
    id: {
      type: String,
      default: "upload-images",
    },
    images: {
      type: Array,
      default: [],
    },
    loadingImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reorderImages: [],
    };
  },
  created() {
    this.reorderImages = this.images;
  },
  methods: {
    changeOrder() {
      this.$emit("reorder", this.reorderImages);
    },
  },
};
</script>
<style lang="scss" scoped>
.uploader-images {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  &__body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>
